import React from 'react'
import Publications from './Publications'
import FooterMain from '../FooterView/FooterMain'
import {Helmet} from 'react-helmet-async';

export default function PublicationsMain() {
  return (
    <div>
      <Helmet>
      <title>Publications - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Publications - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Publications - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
      </Helmet>
        <Publications />
        <FooterMain />
    </div>
  )
}
