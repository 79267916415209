import React, { useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import edi from '../../images/edi.png';
import arti from '../../images/articles.png';
import review from '../../images/review.png';

const config = require('../../config.json');

export default function CardTemplate(props) {
    const {displayDetails, handleFetchPDF}=props;

    useEffect(()=>{
       // console.log("props :", props);
    },[])
  return (
    <div >
    <Row style={{padding:'5px'}}>
        {displayDetails.map((item,index)=>
            <Col key={index} md={4}>
                <Card > {item.journalCategory==="Editorial Section"?<img className='img-pub' src={edi} alt="editorial" style={{width:"150px",  marginBottom:"-35px", zIndex:"2999"}}/>:
                  item.journalCategory==="Book Review"?<img className='img-pub' src={review} alt="editorial" style={{width:"150px",  marginBottom:"-35px", zIndex:"2999"}}/>:
                   <img className='img-pub' src={arti} alt="editorial" style={{width:"150px",  marginBottom:"-35px", zIndex:"2999"}}/>}
                  <div className="geeks">
                  <img style={{height:"180px"}} src={`${config.s3.imginvokeUrl}${item.journalID}.jpeg`} className="journal-img img-fluid rounded" onClick={event=>handleFetchPDF(event, item)}/>
                  
                   </div>
                {/*<Card.Img variant="top" src={`${config.s3.imginvokeUrl}${item.journalID}.jpeg`} className="img-fluid rounded geeks" />*/}
                <Card.Body style={{height:"300px"}}>
                <Card.Title  className="card-title headertruncate" onClick={event=>handleFetchPDF(event, item)}>{item.journalName}</Card.Title>
                <Card.Text style={{textAlign:'justify'}} className="card-text truncate">
                    {item.description}
                
                </Card.Text>
                </Card.Body>
                <Card.Footer>
                <button className='btn rounded btn-wrap p-2' onClick={event=>handleFetchPDF(event, item)}>Read More...</button>
                </Card.Footer>
                </Card>
            </Col>)}
      </Row>
    </div>
  )
}
