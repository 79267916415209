import React from 'react'
import AboutUs from './AboutUs'
import FooterMain from '../FooterView/FooterMain'

export default function AboutMain() {
  return (
    <div>
        <AboutUs />
        <FooterMain />
    </div>
  )
}
