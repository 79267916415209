import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Row, Col}  from 'react-bootstrap';

export default class FooterMain extends Component {
  render() {
    return (
        <div className='footer-main'  >
        <Row className='contact-view'>
  <Col md={4}>
  <h3>About VJTR Online</h3> 
  <p className='' >Vidyajyoti Journal fosters reflection on social and cultural issues in South Asia and elsewhere in the light of the Christian faith and other religious and philosophical traditions.</p>

    </Col>
    <Col md={4} >
      <h3 >Quick Links</h3>
     <p> <Link  to="/privacy_policy"><i className='fa fa-check-square p-1'></i>Privacy Policy</Link></p>
      <p><Link  to="/disclaimer"><i className='fa fa-lock p-1'></i>Disclaimer</Link>   </p> 
  
    </Col>
    <Col md={4}>
    <h3 >Contact Details
        </h3>
<p className=''>Vidyajyoti Journal <br/>
4A Rajniwas Marg<br/>
Delhi - 110 054,
India<br/>
Email: vjtr75@gmail.com<br/>
Mob: +91 70116 81956
<br/>
  </p>
    </Col>
    </Row>
  </div>
    )
  }
}
