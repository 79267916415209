import React, {useState} from 'react';
import axios from "axios";
import {Row, Col} from 'react-bootstrap';
import FooterMain from '../FooterView/FooterMain';
const config = require('../../config.json');

const Contact = () => {

const [queryDetails, setQueryDetails] = useState({name:'', email:'', message:'', phoneNo:''});
const [dispMsg, setDispMsg] = useState('');
const [buttonClicked, setButtonClicked] = useState(false);

const handleSubmit=async(event)=>{
  event.preventDefault();
  //console.log("Query details : ", query);
  setDispMsg('');
setButtonClicked(true);
try{
      const {name, email, phoneNo, message} = queryDetails;
if(name===""||email===""||message===""||phoneNo===""){
  setDispMsg("Kindly fill all the details to share your Query/Feedback");
}else{
  const inputParams={
    name : name,
    phoneNo : phoneNo,
    emailID : email,
    desc : message
  };
  //console.log("Params : ", inputParams);

await axios.post(`${config.api.invokeUrl}/contact_us/query`, inputParams,{
  headers: {
  //Authorization: access_token,
  'x-api-key':config.api.key
  }})
  .then((response)=>{
    if (response.status===200){
      setDispMsg("Thank you for your Query/feedback! our team will get back to you soon!");
      setQueryDetails({...queryDetails, name:'', email:'', message:'', phoneNo:''})
      setButtonClicked(false);
    }
  })
  .catch((error)=>{
    //console.log("Error : ", error);
    setDispMsg("Error Occured : "+error.message);
    setButtonClicked(false);
  });
}
setButtonClicked(false);
}
catch(err){
setDispMsg("Error Occured : "+err.message);
setButtonClicked(false);
}
}

  return (
    <div >
      <section id="contact" className=" wow fadeInUp" style={{borderRadius:"20px"}}>
        <div className='contact-sec'>

       <h3 className='text-center' style={{fontFamily:"Young Serif"}}>Contact Us</h3>
      <div >
        <div className="contactus info text-center">
          
          <p >VIDYAJYOTI JOURNAL,  4/A RAJ NIWAS MARG, DELHI – 110 054, INDIA</p>
        </div>
      </div>
      <Row >
      <Col md={4} >
      <div className="info" >
        <div>
          <i className="fa fa-map-marker"></i>
          <p >VIDYAJYOTI JOURNAL, <br/> 4/A RAJ NIWAS MARG, <br/> DELHI – 110 054, <br/> INDIA</p>
        </div>

        <div className="email">
          <i className="fa fa-envelope"></i>
          <p>vjtr75@gmail.com</p>
        </div>

        <div>
          <i className="fa fa-phone-volume"></i>
          <p>+917011681956</p>
        </div>

      </div>
      <div className="social-links text-center">
        <a href="#" className="p-2 twitter"><i className="fab fa-twitter"></i></a>
        <a href="#" className="p-2 facebook"><i className="fab fa-facebook"></i></a>
        <a href="#" className="p-2 instagram"><i className="fab fa-instagram"></i></a>
        <a href="#" className="p-2 google-plus"><i className="fab fa-google-plus"></i></a>
        <a href="#" className="p-2 linkedin"><i className="fab fa-linkedin"></i></a>
      </div>
      </Col>
      <Col className='text-center' md={4}>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.5762913972094!2d77.21943361519469!3d28.672402882402054!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfda0e1b6e4eb%3A0x32051ba490c17c76!2sVidya+Jyothi+College+of+Theology!5e0!3m2!1sen!2sin!4v1504608752508" id="mapframe" frameBorder="0" allowFullScreen style={{height:"300px"}}></iframe>
      </Col>
      <Col md={4} >
      <div className="form">
        <div id="sendmessage">Your message has been sent. Thank you!</div>
        <div id="errormessage"></div>
        <form action="" method="post" role="form" className="contactForm">
          <div className="form-group" >
            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" 
            value={queryDetails.name} onChange={event=>setQueryDetails({...queryDetails, name:event.target.value})} data-msg="Please enter at least 4 chars" />
            <div className="validation"></div>
          </div>
          <div className="form-group" >
            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" 
            value={queryDetails.email} onChange={event=>setQueryDetails({...queryDetails, email:event.target.value})} data-msg="Please enter a valid email" />
            <div className="validation"></div>
          </div>
          <div className="form-group">
            <input type="text" className="form-control" name="phoneno" id="subject" placeholder="Phone No" 
            value={queryDetails.phoneNo} onChange={event=>setQueryDetails({...queryDetails, phoneNo:event.target.value})} data-rule="minlen:4" data-msg="Enter your phone number with country code" />
            <div className="validation"></div>
          </div>
          <div className="form-group">
            <textarea className="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" 
            value={queryDetails.message} onChange={event=>setQueryDetails({...queryDetails, message:event.target.value})} placeholder="Message"></textarea>
            <div className="validation"></div>
          </div>
          {dispMsg}
          <div className="text-center"><button type="submit" className='btn-secondary rounded p-2 btn-wrap' onClick={event=>handleSubmit(event)}>Send Message</button></div>
        </form>
      </div>
      </Col>
      </Row>
      </div>
      </section>
  </div>
  )
}
export default Contact;