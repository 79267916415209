import React from 'react';
import Contact from './Contact';
import FooterMain from '../FooterView/FooterMain';
import {Helmet} from 'react-helmet-async';

export default function ContactMain() {
  return (
    <div>
      <Helmet>
      <title>Contact Us - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Contact Us - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Contact Us - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
      </Helmet>

        <Contact />
        <FooterMain />
    </div>
  )
}
