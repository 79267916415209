import React, { Component } from 'react';
import logo from '../../images/logo-new.png';
import vjgal from '../../images/images/slider3.jpg';
import {Row, Col}  from 'react-bootstrap';
import prof1 from '../../images/images/Edwin.jpg';
import prof2 from '../../images/images/Anil.jpg';
import prof3 from '../../images/images/Peter.jpg';
import {Helmet} from 'react-helmet-async';
import FooterMain from '../FooterView/FooterMain';


export default class AboutUs extends Component {
  render() {
    return (
      <div className='subscription-section m-2  '>
      <Helmet>
      <title>About Us - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="About Us - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="About Us - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
      </Helmet>

      <div className='p-3 m-3 text-center card'>
            <Row >

         
            <Col md={4}>
              <div style={{padding:"70px 0", textAlign:"center"}}>
                  <img className='img-fluid' src={logo} alt="logo" />
                  </div>  
                <br/>
               
            </Col>
            <Col md={8} style={{textAlign:"justify", lineHeight:"2.5", paddingLeft:"2%"}}>
         <p className='p-2'>VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION (VJTR) is published 12 times a year from the Vidyajyoti College of Theology, Delhi, India by Vidyajyoti Educational and Welfare Society (VIEWS). 
            The College and the Journal are both works of the Society of Jesus. Begun with the title The Clergy Monthly in 1938, 
            the Journal assumed its present title in 1975.
            </p>   
 <p className='p-2'>
 This monthly publication reaches nearly 4000 subscribers all over the world, including most theological colleges of English-speaking countries.
 Its main scope is to foster reflection on social and cultural issues in South Asia and elsewhere in the light of the Christian faith 
 and other religious and philosophical traditions. It covers areas of Christian theology and service, interreligious dialogue, 
 Indian theology, and social questions significant for the Christian faith and society today.
 </p>

<p className='p-2'>
VIDYAJYOTI JOURNAL seeks to promote Indian ways of Christian witnessing and theologizing. 
 It is principally, not exclusively, at the service of all who exercise responsibility and leadership in the Christian community 
 – Clergy, Religious and Laity. It mediates to them the ministry of Indian theologians and theologians from other nations.
</p>

<p className='p-2'>
The Journal respects the creative freedom of theological and pastoral research within the parameters of the Gospel and 
 of the Christian faith. Published from within the rich and diverse perspectives of the Catholic tradition, it remains open to 
 other views in a spirit of dialogue and search for deeper truth, wherever it is found. The different points of view expressed in 
 the Journal may not necessarily agree with that of the Editorial Board or the Faculty of Vidyajyoti College of Theology.  
</p>

            </Col>
        </Row>
        </div>

        <div className='p-3 m-3 text-center'>
    
    <h3 className="text-center"  >
    Board of Editors/Officials
    </h3>
    <Row>
      <Col md={4}>
<div className='text-center' >
<div className='geeks' >
<img  src={prof1} alt="Fr. Edwin Rodrigues SJ" className="geeks" style={{ width:"300px", maxHeight:"300px", borderRadius:"70px", paddingBottom:"5px"}}/>
</div>
<h5 >Dr. Fr. Edwin Rodrigues SJ</h5>
<p> Editor & Publisher</p>
</div>
      </Col>
      <Col md={4}>
      <div className='text-center'>
      <div className='geeks'>
      <img src={prof2} alt="Fr. Anil D'Almeida SJ" className="geeks" style={{ width:"300px", maxHeight:"300px", borderRadius:"70px", paddingBottom:"5px"}}/>
      </div>
<h5 >Dr. Fr. Anil D'Almeida SJ</h5>
<p> Book Review Editor</p>
</div>
      </Col>
      <Col md={4}>
      <div className='text-center'>
      <div className='geeks'>
      <img src={prof3} alt="Mr. Peter Francies" className="geeks" style={{ width:"300px", maxHeight:"300px", borderRadius:"70px", paddingBottom:"5px"}}/>
      </div>
<h5 >Mr. Peter Francies</h5>
<p> Office Secretary</p>
</div>
      </Col>
     
      </Row>
    
</div>
{/*}
<div className="container">
    
    <h3 className="text-center" >
    Staff Members
    </h3>

    <Row>
      <Col md={3}>
<div className='card text-center' >

<img src={prof1} alt="test" style={{maxHeight:"250px", borderRadius:"8px"}}/>
<h5 >Dr. Joseph Rajakumar, SJ</h5>
<p> Principal</p>
</div>
      </Col>
      <Col md={3}>
      <div className='card text-center'>
      <img src={prof1} alt="test" style={{maxHeight:"250px", borderRadius:"8px"}}/>
<h5 >Dr. Milianus Beck, SJ</h5>
<p> Assosiate Professor</p>
</div>
      </Col>
      <Col md={3}>
      <div className='card text-center' style={{}}>
      <img src={prof1} alt="test" style={{maxHeight:"250px", borderRadius:"8px"}}/>
<h5 >Dr. Shalini Mulackal, PBVM</h5>
<p> Book Review Editor</p>
</div>
      </Col>
      <Col md={3}>
      <div className='card text-center' style={{}}>
      <img src={prof1} alt="test" style={{maxHeight:"250px", borderRadius:"8px"}}/>
<h5 >Dr. Stanislaus Alla, SJ</h5>
<p> Publisher</p>
</div>
      </Col>
      </Row>
    
</div>
    */}

      </div>
    )
  }
}
