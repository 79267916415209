import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { ProductProvider } from './ProductProvider';
import {Amplify} from 'aws-amplify';
import config from './config';
import {HelmetProvider} from 'react-helmet-async';

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
});

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
    <ProductProvider>
      <App />
    </ProductProvider>
    </HelmetProvider>
  </React.StrictMode>,
document.getElementById('root')
);
