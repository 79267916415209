
import React from 'react';
//import { Image } from 'react-bootstrap';
import './preloader.css';
import logo from '../../images/logo.png';

//import mdulogo from "../images/mdulogo.png";

export default () => {

  return (
    <>
      {/*}  <div className={"loading"}>Loading&#8230;
        </div>*/}
        <div className="fp-container">
           {/*} <img src={Spinner} className="fp-loader" alt="loading" />*/}
           <section className="preloaderidntica">
		<div className="spinner">
		</div>
		<span><img src={logo} alt="" style={{width: "100px", height:"100px"}}/></span>
        </section> 
        </div>
    </>
    );
};
