import React,{useState, useEffect, useContext} from 'react'
// Import the main component
import { Viewer } from '@react-pdf-viewer/core'; // install this library
// Plugins
//import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
//import { searchPlugin } from '@react-pdf-viewer/search';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'; //install this library
//import { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
//import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Worker
import { Worker } from '@react-pdf-viewer/core'; // install this library
import {Row, Col, Card, Button} from 'react-bootstrap';
//import { pdfJournalData } from '../../ProData';
import { Alert} from 'reactstrap';
//import { Col, Row, Button} from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faEdit, faPenNib } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../Components/preloader/Preloader";
import { Auth } from "aws-amplify";
import { faReadme } from '@fortawesome/free-brands-svg-icons';
//import AddPDF from './AddPDF';
//import pope from '../../assets/img/popefrancis.jpg';
const config = require('../../config.json');

export const ViewJournal = () => {
  // for onchange event
  const { alertClose, alertDetails, setAlertDetails, pdfFormType, setPDFFormType, setPDFDetails } = useContext(ProductContext);

  const [pdfFile, setPdfFile]=useState(null);
  const [showPDF, setShowPDF]=useState(false);
  const [pdfJournalDetails, setPDFJournalDetails] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [collapsed, setCollapsed] = useState(true);


  // Create new plugin instance
  //const defaultLayoutPluginInstance = defaultLayoutPlugin();
  //const searchPluginInstance = searchPlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  useEffect(() => {
    fetchJournalDetails();   
  }, [])

  const toggle = () => {
    //   console.log("clicked");
         setCollapsed(!collapsed);
  }

  const fetchJournalDetails=async()=>{
    try{
      setLoaded(false);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      //const clientID="VJJournal";
      const searchOption="Status";
      const searchValue="Active";
      await axios.get(`${config.api.invokeUrl}/journal/search?searchOption=${searchOption}&searchValue=${searchValue}`,{
          headers: {
            Authorization: access_token,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
        console.log("Journal Response : ", response);
        setPDFJournalDetails(response.data);
          setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.data.length + " - " + searchOption + " Journals Found"
          });  
          //console.log(response);
          setLoaded(true);
      }).catch((error)=>{
          //console.log(error);
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
          setLoaded(true);
          //setDispMsg(error.message);
      })
  
  }catch(error){
      setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      setLoaded(true);
  }
  }
  

  const transform = (slot) => ({
      ...slot,
      Download: () => <></>,
      DownloadMenuItem: () => <></>,
      Open: () => <></>,
      OpenMenuItem: () => <></>,
      Print: () => <></>,
      PrintMenuItem: () => <></>,
      //EnterFullScreen: () => <></>,
      //EnterFullScreenMenuItem: () => <></>,
      SwitchTheme: () => <></>,
      SwitchThemeMenuItem: () => <></>,
  });

const handleFetchPDF = async(e, item) => {
    e.preventDefault();
    //console.log("fetch : ", item);
    try{
      const fetchFile=item.journalID+".pdf";
      console.log("fetch file :", fetchFile);
      const bucketName='docs.vidyajyotijournal.com/pdfs';
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      await axios.get(`${config.api.invokeUrl}/presigned/downloader?fileName=${fetchFile}&bucketName=${bucketName}`,{
        headers: {
          Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
     ).then(async(response)=>{
          //console.log("Response : ", response);
          setPdfFile(response.data.downloadURL);
          setShowPDF(true); 

          /*await axios.get(response.data.downloadURL,{
            responseType:'arraybuffer',
            headers: {
              "Content-Type": "application/json",
              "Accept":"application/pdf",
              //"content-disposition": "attachment; filename=test.pdf",
              "access-control-allow-origin" : "*",
            }
          })
          .then(function(pdfresponse){
           console.log("pdf response : ", pdfresponse);
           let blob = new Blob([pdfresponse.data], {type: 'application/pdf'});
           const link = document.createElement('a');
           link.href = URL.createObjectURL(blob);
           console.log("link.href : ", link);
          // setPdfFile(pdfresponse.data);
          // setShowPDF(true); 
 
          })*/
      }).catch((error)=>{
          console.log("Error1 : ", error);
          //setDispMsg("Error Occured - ", error.message);
      })        
    }catch(error){
        console.log("Error2 : ", error);
    }
}

const handleBack=(e)=>{
  e.preventDefault();
  setPdfFile('');
  setShowPDF(false); 
}

const handlePageChange = (e) => {
  localStorage.setItem('current-page', `${e.currentPage}`);
};

const handleSwitchTheme = (theme) => {
  localStorage.setItem('current-theme', theme);
};




  return (
    <div>
       <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

        <section id="speakers">
      <div className="" data-aos="fade-up">
        <div className="section-header text-center">
          <h4 >Publications</h4>
          <p>Here are some of our Publications</p>
        </div>
    <div className="row ">  
    <div className='container' >
      <Row>
     {pdfJournalDetails.map((item,index)=>
        <Col key={index} md={3} className='text-center card p-2' style={{border:"5px solid #07096f", borderRadius:"8px"}}>
             <div className="speaker" data-aos="fade-up" data-aos-delay="100">
              <img src={item.img} alt="Speaker 1" className="img-fluid rounded" />
            </div>
         <div className='subscription-details'>
            <h5 >{item.header}</h5>
                <p>Author: {item.author}</p>
                <p>Publisher: {item.publisher}</p>
                <p>Number of Pages: {item.pages}</p>
                <p>Publishing Year:{item.publishyear}</p>
                <p>Indian Price {item.price} </p> 
                <p>Intl. Price {item.IntPrice}</p>
                <div className="social">
                  <a href=""><button className='btn-warning rounded p-2' onClick={event=>handleFetchPDF(event,item)}> Click here to Read More </button></a>  
                </div>
              </div>
        </Col>       
           )}
          </Row>     
          </div>
        </div>
      </div>
    </section>
  

<Row style={{padding:"5px"}}> 
    {!showPDF && pdfJournalDetails.map((item,index)=><>
      <Col  md={4}>
            <Card key={index}>
                <Card.Img style={{height:'200px'}}  variant="top" src={`${config.s3.imginvokeUrl}${item.journalID}.jpeg`} />
                <Card.Body>
                <Card.Title style={{fontSize:"14px", height:'50px', textAlign:"center"}}>{item.journalName} <p style={{fontSize:"12px", height:'20px'}}><i>{item.authorName} </i></p></Card.Title>
                <Card.Text style={{fontSize:"12px", height:"130px", overflow:'hidden'}}>
                    {item.description}
                </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">{item.rating}</small>
                    <div className='d-flex justify-content-between'>
                                          
                      <FontAwesomeIcon icon={faReadme} onClick={event=>{handleFetchPDF(event, item)}}/>
                     
                    </div>
                    <div >
                     
                    </div>
                   <div>
                     
                   </div>
                   
                </Card.Footer>
            </Card>
        
       </Col>
    </>)}
    
     </Row>
    </div>
  )
}

export default ViewJournal;