import React from 'react'
import FooterMain from '../FooterView/FooterMain'

export default function Privacy() {
  return (
    <>
    <div>
        <h3>Privacy Policy</h3>
        <p>This privacy statement describes how Vidyajyoti collects and uses the personal information you provide on our websites: www.Vidyajyoti.org. It also describes the choices available to you regarding our use of your personal information and how you can access and update this information. Vidyajyoti is committed to protecting the privacy of users of its website and services. To better inform you of our policy concerning user privacy, we have adopted the following terms.</p>

<h4>Information Collection</h4>
<p>When Author submit a manuscript/Research Paper/Abstract of Research Paper to Vidyajyoti, Vidyajyoti collect various information about the Paper and Authors. Information such as Author’s name, Designation, Name of Institute/University, Email address, and Contact Number. This information may be combined with information we obtain from public sources, business partners, or other companies. Vidyajyoti also Collect the information regarding your Research paper. i.e., Title of Paper, Authors Details, Area of Research, Abstract, Index Terms, and Research Contents. Once you apply for publication, and sign in to our services, you are not anonymous to us. Security of such information is a high priority for us. Vidyajyoti automatically receives and records information on our server logs from the information that your browser sends to us, including your IP address, cookies, and the page you request.</p>

<h4>Use of Information</h4>
<p>The information collected may be used by Vidyajyoti for the purpose of operating and improving the Vidyajyoti Website, fostering a positive user experience, and delivering services that we offer. We may also use the information we gather to inform you of other Notifications/services available from the Vidyajyoti Website or to contact you about your opinion of current services or potential new services that may be offered. E-mail addresses of authors/visitors who place electronic orders/query with us are used for reply purposes only. We also collect e-mail addresses from people who subscribe to our e-mail listserv. If you do not want to receive e-mail from us in the future, please let us know by sending e-mail to us and telling us that you do not want to receive e-mail from the Press. Vidyajyoti may use your contact information in order to send you e-mail, postal mail, or other communications regarding updates at the Vidyajyoti Website, such as upcoming issue related details, different deadlines dates, upcoming Conference or many future proposed details which may be of interest to you. Vidyajyoti will provide the authors published paper details to our indexing partner any time without notice to authors.</p>

<h4>Information Sharing and Disclosure</h4>
<p>Vidyajyoti Publication does not rent, sell, or share personal information about authors with other people or non-affiliated companies except to provide products or services you've requested, when we have authors permission, or under the following circumstances: We provide the information to trusted partners who work on behalf of or with Vidyajyoti Publication under confidentiality agreements. These companies may use your personal information to help Vidyajyoti Publication communicate with you about offers from Vidyajyoti Journals Publication and our marketing partners. However, these companies do not have any independent right to share this information. We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Vidyajyoti Publication's terms of use, or as otherwise required by law. We transfer all the information about authors and paper if Vidyajyoti Publication is acquired by or merged with another company. Vidyajyoti Publication works with vendors, partners, advertisers, and other service providers in different industries and categories of business.</p>

<h4>Changes to this Privacy Policy</h4>
<p>Vidyajyoti Publication may update this policy. Vidyajyoti will notify you about significant changes in the way we treat personal information by sending a notice to the corresponding author’s email address specified in your information with Vidyajyoti or by placing a prominent notice on our site. If you have any questions or suggestions about this Policy, please contact us at: editor@Vidyajyoti.org</p>
    </div>
    <FooterMain/>
    </>
  )
}
