import React from 'react'
import Subscription from './Subscription'
import FooterMain from '../FooterView/FooterMain'
import {Helmet} from 'react-helmet-async';

export default function SubscriptionMain() {
  return (
    <div>
    <Helmet>
      <title>Subscription - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Subscription - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Subscription - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>

        <Subscription />
        <FooterMain />
    </div>
  )
}
