import { useState, useContext } from 'react'
import { NavLink, useHistory } from 'react-router-dom';
import { ProductContext } from '../../ProductProvider';
import { Auth } from "aws-amplify";
import logo from '../../images/logo.png';
import './Navbar3.css';

export default function Navbar3() {
    let history = useHistory();
    const [loaded, setLoaded] = useState(true);
  //const [showNavbar, setShowNavbar] = useState(false)
  const { loggedUser, setLoggedUser} = useContext(ProductContext);

  const handleLogOut = async(event)=>{
    event.preventDefault();
    try{
        setLoaded(false);
        Auth.signOut().then(() => {
            setLoggedUser({...loggedUser,
                username: '',
                isAuthenticated: false,
                email:'',
                phoneno:'',
                address:'',
                startDate:'',
                clientID:'',
                profile:'',
                orgName:''
            });
            //setAccountsFetched(false);
            //clearCart();
            setLoaded(true);
            history.push("/");
          })        
        }catch(error){
            setLoaded(true);
        //console.log("error ", error);
    }
}

return (
<div>
<nav>
  <div className="wrapper">
  <span >
    <NavLink className="navbar-brand logo" to="/" exact>
            <img src={logo} /> 
    </NavLink>
    <NavLink className="navbar-brand" style={{color:"white"}} to="/" exact>
        Vidyajyoti Journal
    </NavLink>
  </span>
    {/*<span style={{display: "flex"}}><div className="logo">
        <NavLink className="navbar-brand" to="/" exact>
            <img src={logo} /> 
        </NavLink>
        </div>
        <div style={{alignItems:"left", marginTop:"0px"}}>
        <NavLink className="navbar-brand " style={{color:"white"}} to="/" exact>
        Vidyajyoti Journal
      </NavLink>
      </div>
      </span>*/}

    <input type="radio" name="slider" id="menu-btn"/>
    <input type="radio" name="slider" id="close-btn"/>
    <ul className="nav-links" style={{marginTop:"10px"}}>
      <label for="close-btn" className="btn close-btn"><i className="fas fa-times"></i></label>
      <li>
              <NavLink to="/">Home</NavLink>
            </li>
      <li>              
        <NavLink to="/about">About</NavLink>
    </li>
    <li>
              <NavLink to="/subscription">Subscription</NavLink>
            </li>
            <li>
              <NavLink to="/publication">Publications</NavLink>
            </li>
            <li>
              <NavLink to="/archives">Archives</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
            {!loggedUser.isAuthenticated ? 
            <li>
              <NavLink to="/signin" exact>
              <i className="fa fa-sign-in"></i>Sign In
              </NavLink></li>:
      <li>
        <a href="#" className="desktop-item">Hi {loggedUser.username}!</a>
        <input type="checkbox" id="showDrop"/>
        <label for="showDrop" className="mobile-item">Hi {loggedUser.username}!</label><span className='caret'></span> 
        <ul className="drop-menu">
          <li><NavLink to="/myprofile" >My Profile</NavLink></li>
          <li><NavLink to="#" onClick={(event)=>{handleLogOut(event)}} >Sign Out</NavLink></li>
        </ul>
      </li>}
      {/*<li>
        <a href="#" className="desktop-item">Mega Menu</a>
        <input type="checkbox" id="showMega"/>
        <label for="showMega" className="mobile-item">Mega Menu</label>
        <div className="mega-box">
          <div className="content">
            <div className="row">
              <img src="https://fadzrinmadu.github.io/hosted-assets/responsive-mega-menu-and-dropdown-menu-using-only-html-and-css/img.jpg" alt=""/>
            </div>
            <div className="row">
              <header>Design Services</header>
              <ul className="mega-links">
                <li><a href="#">Graphics</a></li>
                <li><a href="#">Vectors</a></li>
                <li><a href="#">Business cards</a></li>
                <li><a href="#">Custom logo</a></li>
              </ul>
            </div>
            <div className="row">
              <header>Email Services</header>
              <ul className="mega-links">
                <li><a href="#">Personal Email</a></li>
                <li><a href="#">Business Email</a></li>
                <li><a href="#">Mobile Email</a></li>
                <li><a href="#">Web Marketing</a></li>
              </ul>
            </div>
            <div className="row">
              <header>Security services</header>
              <ul className="mega-links">
                <li><a href="#">Site Seal</a></li>
                <li><a href="#">VPS Hosting</a></li>
                <li><a href="#">Privacy Seal</a></li>
                <li><a href="#">Website design</a></li>
              </ul>
            </div>
          </div>
        </div>
  </li>*/}
    </ul>
    <label for="menu-btn" className="btn menu-btn"><i className="fas fa-bars"></i></label>
  </div>
</nav>
    </div>
  )
}
