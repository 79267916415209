import React, { useState } from 'react';
import { Auth } from "aws-amplify";

const ProductContext = React.createContext();;

const ProductProvider = (props) => {

    const [loggedUser, setLoggedUser] = useState({ username: 'Guest', email: '', phoneno: '', startDate: '', address: '', name: '', clientID:'', orgName:'', profile:'', isAuthenticated: false, gstNo:'' });
    const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
    const [actionClick, setActionClick]=useState('');

    const alertClose=()=>{
      setAlertDetails({
          ...alertDetails,
          style:"",
          open:false,
          message:""
      });
  }

    const validateLogin = async () => {
        try {
          Auth.currentAuthenticatedUser()
            .then((user) => {
             //console.log("User : ", user);
             //const familyName=user.attributes.family_name;
              setLoggedUser({
                ...loggedUser,
                name:user.attributes['name'],
                email:user.attributes['email'],
                profile:user.attributes['profile'],
                DOB:user.attributes['birthdate'],
                website:user.attributes['website'],
                nickname:user.attributes['nickname'],
                gender:user.attributes['gender'],
                nickname:user.attributes['family_name'],
                phone_number:user.attributes['phone_number'],
                isAuthenticated:true,
                    });
            })
            .catch((error) => {
                //console.log("ERror : ", error);
              setLoggedUser({
                ...loggedUser,
                username: '',
                isAuthenticated: false,
                email:'',
                phoneno:'',
                address:'',
                startDate:'',
                clientID:'',
                profile:'',
                orgName:'',
                gstNo:''
              });
            })
        } catch (error) {
          console.log(error);
        }
      }
    
 
    return (
        <ProductContext.Provider value={{
            loggedUser,
            setLoggedUser,
            validateLogin,
            alertDetails, 
            setAlertDetails,
            alertClose,
            actionClick,
            setActionClick
        }}>
            {props.children}
        </ProductContext.Provider>
    )
}

export { ProductProvider, ProductContext };