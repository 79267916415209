import React from 'react'
import SliderMain from '../../Components/Slider/SliderMain';
import "animate.css/animate.min.css";
//import { AnimationOnScroll } from 'react-animation-on-scroll';
import Contact from '../Contact/Contact';
//import AboutUs from '../About/AboutUs';
//import Publications from '../Publications/Publications';
import Subscription from '../Subscription/Subscription';
//import ArticlesView from '../Articles/ArticlesView';
import ViewMagazine1 from '../Journal/ViewMagazine1';
import FooterMain from '../FooterView/FooterMain';
import {Helmet} from 'react-helmet-async';

const Home = () => {
  return (
    <div   >
    <Helmet>
      <title>VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>
    <SliderMain/>
    <div className='home-sections'>
        <ViewMagazine1 />
        <Subscription/>
    </div>
    <Contact />
    {/* <h1 className="text-center" style={{paddingTop: "0px"}}>
        Home
    </h1> */}
    {/*} <AnimationOnScroll animateIn="animate__fadeInRightBig">
        <AboutUs/>
        <ViewMagazine1 />
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeInLeftBig">
        <Publications/>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__fadeInRightBig">
      <Subscription/>
      </AnimationOnScroll>
    <AnimationOnScroll animateIn="animate__fadeInLeftBig">
        <Contact />
      </AnimationOnScroll>*/}
    <FooterMain/>
  </div>
  )
}

export default Home;