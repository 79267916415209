import React,{useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { ProductContext } from '../../ProductProvider';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CardTemplate from '../Articles/CardTemplate';
import { Auth } from "aws-amplify";
import { useHistory } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Helmet} from "react-helmet-async";
import Preloader from "../../Components/preloader/Preloader";
import { ProgressBar } from '@react-pdf-viewer/core';
import { faBackward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {Viewer } from '@react-pdf-viewer/core'; // install this library
// Plugins
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Worker
import { Worker } from '@react-pdf-viewer/core'; // install this library
import FooterMain from '../FooterView/FooterMain';

const config = require('../../config.json');
const writingGif=require("../../images/background/writing.gif");

export default function ViewArchives() {
    let history = useHistory();

    const { alertDetails, setAlertDetails, loggedUser } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [archiveList, setArchiveList] = useState([]);
    const [displayList, setDisplayList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [editorialDetails, setEditorialDetails] = useState([]);
    const [reviewDetails, setReviewDetails] = useState([]);
    const [articleDetails, setArticleDetails] = useState([]);
    const [pdfFile, setPdfFile]=useState(null);
    const [showPDF, setShowPDF]=useState(false);
    const [selectedFile, setSelectedFile]=useState(null);
    const [showCard, setShowCard] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');
    const [progressValue, setProgressValue] = useState(0);

    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const fontColor=`rgba(22, 22, 117, 0.941)`;
  
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    useEffect(() => {
        fetchJournalDetails(); 
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

      const fetchJournalDetails=async()=>{
        try{
          setLoaded(false);
          //const session = await Auth.currentSession();
          //const access_token=session.idToken.jwtToken;
          //const clientID="VJJournal";
          const searchOption="JournalStatus";
          const searchValue="Archive";
          await axios.get(`${config.api.invokeUrl}/journal/static?searchOption=${searchOption}&searchValue=${searchValue}`,{
              headers: {
                //Authorization: access_token,
                'x-api-key': config.api.key
              }}, 
          ).then((response)=>{
            //console.log("Journal Response : ", response);
            if(response.data.length>0){
            const responseData=response.data;
            setArchiveList(response.data);
            const publishDate=responseData.map(obj=>(obj.publishDate.substring(0,7)));
            //console.log("Publish Date :", publishDate);
            const sortedArray= initializeValues(publishDate);
            setDisplayList(sortedArray);
            //new Date().toLocaleDateString('en-us', { year:"numeric", month:"short"});
            }
            /*const responseData=response.data;
            setPDFJournalDetails(response.data);
            const tempBookReview = responseData.filter(item => item.journalCategory ==="Book Review");
            const tempEditorials = responseData.filter(item => item.journalCategory ==="Editorial Section");
            const tempArticles = responseData.filter(item => item.journalCategory ==="Articles");
            setEditorialDetails(tempEditorials);
            setArticleDetails(tempArticles);
            setReviewDetails(tempBookReview);
              setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: response.data.length + " - " + searchOption + " Journals Found"
              });  */
              //console.log(response);
              setLoaded(true);
          }).catch((error)=>{
              //console.log(error);
              setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message:"Error Occured - " + error.message
              }); 
              setLoaded(true);
              //setDispMsg(error.message);
          })
      
      }catch(error){
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
          setLoaded(true);
      }
      }


  const initializeValues=(publishDate)=>{
    var uniqueArray = removeDuplicates(publishDate);
    //console.log("uniqueArray : ", uniqueArray);
    //const anbiyamList=churchMaster.chruchAnbiyam;
    var sortedcourseArray = uniqueArray.sort((a, b) => {return a - b;});
    //console.log("sortedcourseArray : ", sortedcourseArray);
    //console.log(new Date(sortedcourseArray[0]).toLocaleDateString('en-us', { year:"numeric", month:"long"}));
    return sortedcourseArray;
    //setStationName(sortedcourseArray);
  }

  
  const removeDuplicates=(publishDate)=> {
    var newArray = [];
    var lookupObject  = {};
    //const anbiyamList=churchMaster.chruchAnbiyam;
    for(var i in publishDate) {
       lookupObject[publishDate[i]] = publishDate[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
}

const handleFetchArchive=(event, item)=>{
    event.preventDefault();
    //console.log("Item : ", item);

    const filteredData=archiveList.filter(obj=>(obj.publishDate).startsWith(item));
    //console.log("Filtered Data : ", filteredData);
    //const tempBookReview = filteredData.filter(item => item.journalCategory ==="Book Review");
    //const tempEditorials = filteredData.filter(item => item.journalCategory ==="Editorial Section");
    //const tempArticles = filteredData.filter(item => item.journalCategory ==="Articles");
    //setEditorialDetails(tempEditorials);
    //setArticleDetails(tempArticles);
    //setReviewDetails(tempBookReview);
    setSelectedList(filteredData);
    if(selectedItem===item){
        setSelectedItem('');
        setShowCard(false);
    }else{
        setSelectedItem(item);
        setShowCard(true);
    }

}

const transform = (slot) => ({
    ...slot,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    Print: () => <></>,
    PrintMenuItem: () => <></>,
    //EnterFullScreen: () => <></>,
    //EnterFullScreenMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
});

const handlePageChange = (e) => {
localStorage.setItem('current-page', `${e.currentPage}`);
};

const handleSwitchTheme = (theme) => {
localStorage.setItem('current-theme', theme);
};

const handleDocumentLoad=(e)=>{
setLoaded(true);
}

const handleBack=(e)=>{
  e.preventDefault();
  setPdfFile('');
  setShowPDF(false); 
}

const handleFetchPDF = async(e, item) => {
  e.preventDefault();
  //console.log("fetch : ", item);
  try{
      if(item.journalCategory==="Book Review"|| item.journalCategory==="Editorial Section"){
          const fetchFile=item.journalID+".pdf";
          //console.log("fetch file :", fetchFile);
          const bucketName='docs.vidyajyotijournal.com/pdfs';
          //const session = await Auth.currentSession();
          //const access_token=session.idToken.jwtToken;
          setSelectedFile(item);
          await axios.get(`${config.api.invokeUrl}/presigned/static?fileName=${fetchFile}&bucketName=${bucketName}`,{
              headers: {
              //Authorization: access_token,
              'x-api-key': config.api.key
              }}, 
          ).then(async(response)=>{
              //console.log("Response : ", response);
              setPdfFile(response.data.downloadURL);
              setShowPDF(true); 

              /*await axios.get(response.data.downloadURL,{
                  responseType:'arraybuffer',
                  headers: {
                  "Content-Type": "application/json",
                  "Accept":"application/pdf",
                  //"content-disposition": "attachment; filename=test.pdf",
                  "access-control-allow-origin" : "*",
                  }
              })
              .then(function(pdfresponse){
              console.log("pdf response : ", pdfresponse);
              let blob = new Blob([pdfresponse.data], {type: 'application/pdf'});
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              console.log("link.href : ", link);
              // setPdfFile(pdfresponse.data);
              // setShowPDF(true); 
      
              })*/
          }).catch((error)=>{
              console.log("Error1 : ", error);
              //setDispMsg("Error Occured - ", error.message);
          })  
      }else if(!loggedUser.isAuthenticated && item.journalCategory==="Articles"){
          history.push("/signin");
      }else if(loggedUser.isAuthenticated && item.journalCategory==="Articles"){
          const fetchFile=item.journalID+".pdf";
          //console.log("fetch file :", fetchFile);
          const bucketName='docs.vidyajyotijournal.com/pdfs';
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          setSelectedFile(item);
          await axios.get(`${config.api.invokeUrl}/presigned/downloader?fileName=${fetchFile}&bucketName=${bucketName}`,{
              headers: {
              Authorization: access_token,
              'x-api-key': config.api.key
              }}, 
          ).then(async(response)=>{
              //console.log("Response : ", response);
              setPdfFile(response.data.downloadURL);
              setShowPDF(true); 
          }).catch((error)=>{
              console.log("Error1 : ", error);
          })  
      }    
  }catch(error){
      console.log("Error2 : ", error);
  }
}
    
  return (
    <>
      <Helmet>
      <title>Archives - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="Archives - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="Archives - VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
      </Helmet>
    {!loaded && <Preloader show={loaded ? true : false} />}
    <Modal
        show={showPDF}
        onHide={() => setShowPDF(false)}
        fullscreen={showPDF}
        size="xl"
        backdrop="static"
        keyboard={false}
        scrollable
        style={{top:5, zIndex:3999}}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
          <div onClick={event=>{handleBack(event)}}>
            <FontAwesomeIcon icon={faBackward} onClick={event=>{handleBack(event)}}/> Go Back</div>
            {showPDF && selectedFile.journalName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundImage: `url(' + magazineBackground + ')`}}>
        {showPDF && <div>     
     {progressValue<100 ?<div  className='justify-content-between' >
              <p style={{ alignItems:'center'}}>Loading... Please Wait...</p>
                <ProgressBar progress={progressValue} />
                <img src={writingGif} alt="Writing Gif"/>
            </div>:null}
        {showPDF &&  pdfFile && <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js">

        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>

          <Viewer fileUrl={pdfFile}  onPageChange={handlePageChange} plugins={[toolbarPluginInstance]} theme="auto"
          onSwitchTheme={handleSwitchTheme} onDocumentLoad={handleDocumentLoad}

          renderLoader={(percentages) => (
            setProgressValue(Math.round(percentages))
        )}
        />
      </Worker>}
    </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleBack}>
            Close
          </Button>
        </Modal.Footer>

      </Modal>
    <div className='p-3 m-3 text-center section-title' data-aos="fade-up">
    <h3 style={{color:`${fontColor}`}}>View Archives</h3>
    </div>
    <div className='p-3 m-3 text-center' data-aos="fade-up">
        {loaded && displayList.length<1 && <h3>No Archive Data Found</h3>}
    <Row >
        {displayList.map((item,index)=>
            <Col key={index} md={4}>
                <a href="#journal"><Card  onClick={event=>handleFetchArchive(event, item)}>
                <Card.Body >
                <Card.Title className="card-highlight" >{new Date(item).toLocaleDateString('en-us', { year:"numeric", month:"long"})}</Card.Title>
                </Card.Body>
                </Card></a>
            </Col>)}
      </Row>
      </div>

      {showCard && <section id="journal">
        <div className='p-3 m-3 text-center' data-aos="fade-up">
            
            {selectedList.length>0 ?<><CardTemplate displayDetails={selectedList}  handleFetchPDF={handleFetchPDF}/></>:null}
            {/*articleDetails.length>0 ?<><CardTemplate displayDetails={articleDetails}  handleFetchPDF={handleFetchPDF}/></>:null}
            {reviewDetails.length>0?<><CardTemplate displayDetails={reviewDetails} handleFetchPDF={handleFetchPDF}/></>:null*/}
        </div>
    </section>}

    <FooterMain />

    </>
  )
}
