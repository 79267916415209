import React, {useContext, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
/*import Courses from './pages/Courses/Courses';
import Contact from './pages/Contact/Contact';*/
import Home from './pages/Home/Home';
/*import Navbar from './Components/Navbar/Navbar';
import NavbarMain from './Components/Navbar/NavbarMain';
import Gallery from './pages/Gallery/Gallery';
import AboutUs from './pages/About/AboutUs';*/

/*Fonts */
import '../src/App.css';
import "./Fonts/Freehand.ttf";
import "./Fonts/BRLNSDB.TTF";
import "./Fonts/MisterEarlBTRegular.TTF";
import "./Fonts/BernhandTango.ttf";
import "./Fonts/Calligraph421BT.TTF";
import "./Fonts/domboldBT.ttf";
import "./Fonts/GilSansMTCondBold.TTF";
import "./Fonts/GilSansMTCondensed.TTF";
import "./Fonts/HARNGTON.TTF";
import "./Fonts/HoboStd.otf";
import "./Fonts/JohnHandyLET.TTF";
import "./Fonts/ParkAvenue.TTF";
import "./Fonts/TektonPro-Bold.otf";
import "./Fonts/TektonPro-BoldCond.otf";
/*import Subscription from './pages/Subscription/Subscription';
import Publications from './pages/Publications/Publications';*/
import SignInMFA from './pages/auth/SignInMFA';
import { ProductContext } from './ProductProvider';
//import useFullPageLoader from "./Components/hooks/useFullPageLoader";
//import ViewJournal from './pages/Journal/ViewJournal';
//import ViewMagazine1 from './pages/Journal/ViewMagazine1';
import Privacy from './pages/Policies/Privacy';
import Disclaimer from './pages/Policies/Disclaimer';
import ForgotPassword from './pages/auth/ForgotPassword';
//import SignUp from './pages/auth/SignUpMFA';
import SubscriptionMain from './pages/Subscription/SubscriptionMain';
import ContactMain from './pages/Contact/ContactMain';
import AboutMain from './pages/About/AboutMain';
import PublicationsMain from './pages/Publications/PublicationsMain';
import ViewArchives from './pages/Journal/ViewArchives';
//import Topbar from './Components/Topbar/Topbar';
import {Helmet} from "react-helmet-async";
import MyProfile from './pages/dashboard/MyProfile';
import Navbar2 from './Components/Navbar2/Navbar2';
import Navbar3 from './Components/Narbar3/Navbar3';

const App = () => {
  const { validateLogin} = useContext(ProductContext);
  //const [loader, showLoader, hideLoader] = useFullPageLoader();

  useEffect(() => {
    //showLoader();
    validateLogin();
    //hideLoader();
  }, []);

  return (
  <>
    <Helmet>
      <title>VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION</title>
      <meta name="description" content="VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" />
      <meta name="keywords" content="VIDYAJYOTI JOURNAL OF THEOLOGICAL REFLECTION" /> 
    </Helmet>
   <Router > 
    {/*} 
       <ReactNavBar />
    <Navbar1 />
  <Navbar2 />*/}
    {/*<NavbarMain/>*/}
    <Navbar3 />
    <main style={{paddingTop: '70px'}} >
      <Switch>
        <Route path="/" exact>
          <Home/>
        </Route>
        <Route path="/subscription" exact>
          <SubscriptionMain/>
        </Route>
        <Route path="/contact" exact>
          <ContactMain/>
        </Route>
        <Route path="/publication" exact>
          <PublicationsMain/>
        </Route>
        <Route path="/archives" exact>
          <ViewArchives/>
        </Route>
        <Route path="/signin" exact>
          <SignInMFA/>
        </Route>
        <Route path="/forgot-password" exact>
          <ForgotPassword/>
        </Route>
        <Route path="/about" exact>
          <AboutMain/>
        </Route>
        <Route path="/privacy_policy" exact>
          <Privacy/>
        </Route>
        <Route path="/disclaimer" exact>
          <Disclaimer/>
        </Route>
        <Route path="/myprofile" exact>
          <MyProfile/>
        </Route>
        <Redirect to="/" />
      </Switch>
    </main>  
   </Router>
   </>

  );
}

export default App;
